import React, { useEffect, useState } from 'react';
import './AnimatedText.css'; // Import CSS for animations

const phrases = ["Best Mods Ever", "Tell Your Mates", "TNKMODS","Your Can Track You Own Links", "Join Today >>>", "Subscribe, Like and Share"];

const AnimatedText = () => {
    const [displayedText, setDisplayedText] = useState('');
    const [isFading, setIsFading] = useState(false);
    const [phraseIndex, setPhraseIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);

    useEffect(() => {
        let typingTimeout;
        let fadeTimeout;

        const typeNextPhrase = () => {
            if (charIndex < phrases[phraseIndex].length) {
                setDisplayedText(prev => prev + phrases[phraseIndex][charIndex]);
                setCharIndex(charIndex + 1);
                typingTimeout = setTimeout(typeNextPhrase, 700); // Adjusted typing speed for slower animation
            } else {
                fadeTimeout = setTimeout(() => {
                    setIsFading(true);
                    // Prepare for next phrase
                    setTimeout(() => {
                        setIsFading(false);
                        setDisplayedText('');
                        setCharIndex(0);
                        setPhraseIndex((prev) => (prev + 1) % phrases.length); // Cycle through phrases
                    }, 700); // Duration of fade out
                }, 5000); // Pause before fading
            }
        };

        typeNextPhrase(); // Start typing the first phrase

        return () => {
            clearTimeout(typingTimeout);
            clearTimeout(fadeTimeout);
        };
    }, [phraseIndex, charIndex]); // Dependencies for effect

    return (
        <div className={`animated-text ${isFading ? 'fade-out' : 'fade-in'}`}>
            {displayedText}
            
        </div>
    );
};

export default AnimatedText;