import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './style.css'; // Make sure this file includes your styles

// Import images
import pes17Image from './ic/pes/images/pes17.webp';
import pes18Image from './ic/pes/images/pes18.webp';
import pes20Image from './ic/pes/images/pes20.webp';
import pes21Image from './ic/pes/images/pes21.webp';

const PES = () => {
  const { version } = useParams();
  const [VersionComponent, setVersionComponent] = useState(null);

  // Define PES versions with images
  const versions = [
    { name: 'PES 17', imageUrl: pes17Image },
    { name: 'PES 18', imageUrl: pes18Image },
    { name: 'PES 20', imageUrl: pes20Image },
    { name: 'PES 21', imageUrl: pes21Image }
  ];

  const loadVersionComponent = async (versionName) => {
    const versionKey = versionName.replace(' ', '').toLowerCase();
    
    try {
      const { default: Component } = await import(`./ic/pes/${versionKey}.js`);
      setVersionComponent(() => Component);
    } catch (error) {
      console.error('Error loading component:', error);
      setVersionComponent(null);
    }
  };

  useEffect(() => {
    if (version) {
      loadVersionComponent(version.replace('-', ' '));
    } else {
      setVersionComponent(null); // Reset component if no version is specified
    }
  }, [version]);

  return (
    <div>
      {!VersionComponent ? (
        <div>
          <h2>Pick The Game!</h2>
          <p>Here, you will find the best PES mods with direct links!</p>
          <div className="card-container">
            {versions.map((version) => (
              <Link to={`/pes/${version.name.replace(' ', '').toLowerCase()}`} key={version.name}>
                <div className="card" onClick={() => loadVersionComponent(version.name)}>
                  <img src={version.imageUrl} alt={version.name} className="card-image" />
                  <div className="card-overlay">
                    <h3>{version.name}</h3>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <div className="selected-version">
          <VersionComponent /> {/* Render the dynamically loaded component */}
          <Link to="/pes" className="back-button">Back to PES Versions</Link>
        </div>
      )}
    </div>
  );
};

export default PES;