import React, { useEffect, useState } from 'react';
import './GTA5Mods.css';

const GTA5 = () => {
  const [messageVisible, setMessageVisible] = useState(true);

  // Toggle visibility of the message every 1.5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setMessageVisible((prevVisible) => !prevVisible);
    }, 1500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="gta5-container">
      <h2 className="gta5-title">Skyrim Mods</h2>
      <p className={`gta5-message ${messageVisible ? 'visible' : 'hidden'}`}>
        No content available yet, keep checking!
      </p>
      {/* Rotating shape animation */}
      <div className="gta5-shape"></div>
    </div>
  );
};

export default GTA5;
