import React from 'react';
import styles from './ExternalFunctionalityPage.module.css';

const ExternalFunctionalityPage = () => {
  const handleButtonClick = () => {
    // Redirect the user to the external PHP file
    window.location.href = 'https://tnkmods.com/api/cnnct/login.php';
  };

  return (
    <div className={styles['efp-container']}>
      <div className={styles['efp-card']}>
        <h2 className={styles['efp-heading']}>External Functionality Notice</h2>
        <p className={styles['efp-paragraph']}>
          To Login Or SignUp You Will visit an external page
        </p>
        <button className={styles['efp-button']} onClick={handleButtonClick}>
          Sure
        </button>
      </div>
    </div>
  );
};

export default ExternalFunctionalityPage;
